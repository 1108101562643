import { Model } from '@vuex-orm/core';
import axios from 'axios';

/**
 * This class represent a Member
 */
export default class HsaInfo extends Model {
	/**
	 * This method will call the api to return a pdf of the member card.
	 * @param {String} username
	 * @param {String} locale
	 * @returns
	 */
	static async getPreviousYearHsaPdf(username, token, locale) {
		try {
			const apiClientPDF = axios.create({
				baseURL: window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS,
				timeout: 120000,
				withCredentials: false,
				headers: {
					'Content-Type': 'application/pdf',
					Accept: 'application/pdf',
					Authorization: token,
					'Accept-Language': locale
				},
				responseType: 'blob'
			});
			const response = await apiClientPDF.get(
				`/${username}/coverages/health-spending-accounts/reports/annual/pdf`
			);

			if (response && (response.status === 200 || response.status === 201)) {
				return response;
			} else {
				this.store().dispatch('error', { name: 'generic' });
			}
		} catch (err) {
			this.store().dispatch('error', { name: 'generic' });
		}
	}

	/**
	 * This method will call the api to return a pdf of the member card.
	 * @param {String} username
	 * @param {String} locale
	 * @returns
	 */
	static async getYearToDateHsaPdf(username, token, locale) {
		try {
			const apiClientPDF = axios.create({
				baseURL: window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS,
				timeout: 120000,
				withCredentials: false,
				headers: {
					'Content-Type': 'application/pdf',
					Accept: 'application/pdf',
					Authorization: token,
					'Accept-Language': locale
				},
				responseType: 'blob'
			});
			const response = await apiClientPDF.get(
				`/${username}/coverages/health-spending-accounts/reports/daily/pdf`
			);
			if (response && (response.status === 200 || response.status === 201)) {
				return response;
			} else {
				this.store().dispatch('error', { name: 'generic' });
			}
		} catch (err) {
			this.store().dispatch('error', { name: 'generic' });
		}
	}

	/**
	 * This method takes in data passed to it to generate a pdf, and download it for the member.
	 * @param {String} data
	 */
	static async downloadHsaPdf(data, filename) {
		const demoMode = sessionStorage.getItem('demoActive');
		if (navigator.appVersion.toString().indexOf('.NET') > 0) {
			window.navigator.msSaveBlob(new Blob([data]), `Identification.pdf`);
		} else {
			let fileURL = require('@/assets/blank-demo-document.pdf');
			if (!demoMode) {
				fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
			}
			const fileLink = document.createElement('a');

			fileLink.href = fileURL;
			fileLink.setAttribute('download', filename);
			document.body.appendChild(fileLink);

			fileLink.click();
		}
	}
}
