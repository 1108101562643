<template>
	<HsaTemplate :page-title="$t('title')">
		<BaseCard automation-id="hsaDescriptionSection">
			<div class="desc-header">
				<span>{{ $t('descriptionSection.header') }}</span>
			</div>
			<div class="desc-link-and-text">
				<span v-if="!saskatchewanUser"
					>{{ $t('descriptionSection.textOne') }}
					<BLink :href="$t('descriptionSection.link')" target="_blank"
						><b>{{ $t('descriptionSection.linkText') }}</b></BLink
					>
					{{ $t('descriptionSection.textTwo') }}</span
				>
				<span v-if="saskatchewanUser">{{ $t('descriptionSection.textOneSBC') }}</span>
			</div>
			<div class="desc-text-three">
				<span>{{ $t('descriptionSection.textThree') }}</span>
			</div>
			<div class="desc-bullet-one">
				<span>{{ $t('descriptionSection.bulletOne') }}</span>
			</div>
			<div class="desc-bullet-two">
				<span>{{ $t('descriptionSection.bulletTwo') }}</span>
			</div>
			<div class="desc-text-five">
				<span>{{ $t('descriptionSection.textFive') }}</span>
			</div>
			<div class="desc-bullet-three">
				<span>{{ $t('descriptionSection.bulletThree') }}</span>
			</div>
			<div>
				<span>{{ $t('descriptionSection.bulletFour') }}</span>
			</div>
		</BaseCard>
		<BaseCard automation-id="hsaYearToDateSection">
			<div class="year-to-date-header">
				<span>{{ $t('yearToDateSection.header') }}</span>
			</div>
			<div class="year-to-date-text-one">
				<span>{{ $t('yearToDateSection.textOne') }}</span>
			</div>
			<div class="year-to-date-text-two">
				<span>{{ $t('yearToDateSection.textTwo') }}</span>
			</div>
			<div class="year-to-date-text-three">
				<span>{{ $t('yearToDateSection.textThree') }}</span>
			</div>
			<BaseButton
				id="yearToDateHsaButton"
				class="mr-2 mb-2"
				automation-id="yearToDateHsaButton"
				variant="secondary"
				:label="$t('button.ytd')"
				@click="getYearToDatePDF"
			/>
			<BaseButton
				id="previousYearHsaButton"
				class="mr-2 mb-2"
				automation-id="previousYearHsaButton"
				variant="secondary"
				:label="$t('button.previous')"
				@click="getPreviousYearPDF"
			/>
		</BaseCard>
	</HsaTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseCard from '@/components/common/card/BaseCard.vue';
import HsaTemplate from '@/pages/coverage/hsa/HsaTemplate.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import HsaInfo from '@/models/HsaInfo';
import { BLink } from 'bootstrap-vue';

// @vue/component
@Component({
	name: 'HsaPage',
	components: {
		BaseCard,
		HsaTemplate,
		BaseButton,
		BLink
	},
	mixins: [IdMixin]
})
export default class HsaPage extends Vue {
	saskatchewanUser = false;
	async getYearToDatePDF() {
		this.$store.dispatch('updateLoading', true);
		let response = await HsaInfo.getYearToDateHsaPdf(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
		await HsaInfo.downloadHsaPdf(response?.data, this.$t('filename.daily'));
		this.$store.dispatch('updateLoading', false);
	}
	async getPreviousYearPDF() {
		this.$store.dispatch('updateLoading', true);
		let response = await HsaInfo.getPreviousYearHsaPdf(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
		await HsaInfo.downloadHsaPdf(response?.data, this.$t('filename.annual'));
		this.$store.dispatch('updateLoading', false);
	}
	mounted() {
		const { brand } = JSON.parse(sessionStorage.getItem('securityPolicy'));
		if (brand && brand === 'SBC') {
			this.saskatchewanUser = true;
		}
	}
}
</script>

<style lang="scss" scoped>
.desc-header {
	font-family: $font-family-headline;
	font-size: 24px;
	margin-bottom: 5px;
}
.desc-link-and-text {
	margin-bottom: 30px;
}
.desc-text-three {
	font-weight: 400;
	margin-bottom: 5px;
}
.desc-bullet-one {
	margin-bottom: 5px;
}
.desc-bullet-two {
	margin-bottom: 20px;
}
.desc-text-five {
	font-family: $font-family-headline;
	font-size: 18px;
	margin-bottom: 5px;
}
.desc-bullet-three {
	margin-bottom: 5px;
}
.year-to-date-header {
	font-family: $font-family-headline;
	font-size: 24px;
	margin-bottom: 10px;
}
.year-to-date-text-one {
	margin-bottom: 20px;
}
.year-to-date-text-two {
	margin-bottom: 20px;
}
.year-to-date-text-three {
	margin-bottom: 20px;
}
</style>

<i18n>
{
  "en": {
	"title": "Health Spending Account (HSA)",  
    "descriptionSection": {
      "header": "Description",
	  "textOne": "Use your Health Spending Account (HSA) to cover medical expenses not covered under your basic or provincial health plan. Download the",
	  "textOneSBC": "Use your Health Spending Account (HSA) to cover medical expenses not covered under your basic or provincial health plan. Please note that the Canadian Revenue Agency defines what qualifies as medical expenses.",
	  "linkText": "HSA - Expense Eligibility Guidelines",
	  "link": "https://docs.medaviebc.ca/members-EN/HSA-EligibleExpenseGuidelineENGFLY141E.pdf",
	  "textTwo": "to find out what’s covered. Please note that the Canadian Revenue Agency defines what qualifies as medical expenses.",
	  "textThree": "You can also use your HSA to cover the following:",
	  "bulletOne": "• The cost of medical treatments beyond your annual maximums (for example, physiotherapy).",
	  "bulletTwo": "• The co-pay amount of eligible medical expenses for you, your spouse, or any qualifying dependents.",
	  "textFive": "Claim Requirements",
	  "bulletThree": "• Paid-in-full receipt showing dates of service",
	  "bulletFour": "• All related medical documentation (i.e. RX from your physician, etc.)"
	},
	"yearToDateSection": {
		"header": "Year-to-Date Summary",
		"textOne": "Your year-to-date summary will show you what you’ve submitted to your health spending account for both the previous year and the current year.",
		"textTwo": "If you’ve submitted something to us, you may notice a delay between when you send in a claim and when it’s posted. Anything submitted to us through our payment system will show up on your year-to-date summary on the following business day.",
		"textThree": "For prescription drugs submitted by your pharmacy over the weekend, it can take up to two business days for the claims to show up on your report."
	},
	"button": {
		"ytd": "Year-to-Date Summary",
		"previous": "Previous Year Summary"
	},
	"filename": {
		"annual": "AnnualReport",
		"daily": "DailyReport"
	}
  },
  "fr": {
	"title": "Compte Gestion-santé (CGS)",  
    "descriptionSection": {
      "header": "Description",
	  "textOne": "Utilisez le compte Gestion-santé (CGS) pour vous faire rembourser les frais médicaux qui ne sont pas couverts par votre régime de soins de santé de base ou par votre régime provincial d’assurance maladie. Téléchargez la fiche",
	  "textOneSBC": "Utilisez le compte Gestion-santé (CGS) pour vous faire rembourser les frais médicaux qui ne sont pas couverts par votre régime de soins de santé de base ou par votre régime provincial d’assurance maladie. pour obtenir plus de renseignements sur la couverture. Veuillez noter que l’Agence du revenu du Canada détermine ce qui constitue des frais médicaux admissibles.",
	  "linkText": "CGS – Admissibilité des dépenses",
	  "link": "https://docs.medaviebc.ca/members-FR/HSA-EligibleExpenseGuidelineFRE.pdf",
	  "textTwo": "pour obtenir plus de renseignements sur la couverture. Veuillez noter que l’Agence du revenu du Canada détermine ce qui constitue des frais médicaux admissibles.",
	  "textThree": "Vous pouvez également utiliser votre CGS pour obtenir le remboursement des frais suivants :",
	  "bulletOne": "• Le coût des traitements médicaux qui dépassent le montant maximum de remboursement annuel de votre régime (p. ex., pour la physiothérapie).",
	  "bulletTwo": "• La quote-part des frais médicaux admissibles pour vous, votre conjoint(e) et toute personne à charge admissible.",
	  "textFive": "Exigences relatives à la demande de règlement",
	  "bulletThree": "• Reçu de paiement intégral sur lequel figurent les dates de service.",
	  "bulletFour": "• Tous les documents médicaux connexes (p. ex., ordonnance du médecin, etc.)"
    },
	"yearToDateSection": {
		"header": "Relevé cumulatif à ce jour",
		"textOne": "Votre relevé cumulatif à ce jour fait état des sommes payées par le compte Gestion-santé pendant l’année précédente et l’année en cours.",
		"textTwo": "Lorsque vous soumettez une demande de règlement, vous pourriez remarquer un certain délai entre le moment où la demande est envoyée et celui où elle figure sur le relevé. Toutes les demandes soumises par l’entremise de notre système de paiement figureront sur votre relevé le jour ouvrable suivant leur envoi.",
		"textThree": "Dans le cas des demandes de règlement pour des médicaments sur ordonnance soumises par votre pharmacie pendant la fin de semaine, il peut s’écouler jusqu’à deux jours ouvrables avant qu’elles ne figurent sur votre relevé."
	},
	"button": {
		"ytd": "Relevé cumulatif à ce jour",
		"previous": "Relevé de l’année dernière"
	},
	"filename": {
		"annual": "Releve_annuel",
		"daily": "Releve_quotidien"
	}
  }
}
</i18n>
